/**
 * A Typescript library which contains shared logic for all of my webserver projects.
 * @module
 */

/**
 * Publishes a highly-opinionated custom analytics event to the server.
 *
 * @example How to use:
 * ```ts
 * import { scitylana } from "@todd/webserver-base";
 *
 * document.addEventListener("DOMContentLoaded", scitylana);
 * ```
 */
export async function scitylana(e: Event): Promise<void> {
  e.preventDefault();

  const response: Response = await fetch("/api/v1/scitylana", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: new URLSearchParams({
      "user_agent": navigator.userAgent,
      "url": window.location.href,
      "referrer": document.referrer,
      "screen_width": window.innerWidth.toString(),
    }),
  });
  if (!response.ok) {
    console.error("Failed scitylana: ", response);
  }
}

// denoCacheMetadata={"headers":{"x-robots-tag":"noindex","expires":"Mon, 24 Nov 2025 00:30:43 GMT","etag":"\"9fce94f15a8e0cfd284efe8c55bacac2\"","x-goog-storage-class":"STANDARD","via":"1.1 google","alt-svc":"h3=\":443\"; ma=2592000,h3-29=\":443\"; ma=2592000","cross-origin-resource-policy":"cross-origin","access-control-expose-headers":"*","x-jsr-cache-id":"SFO-fbae48ad","x-content-type-options":"nosniff","cache-control":"public, max-age=31536000, immutable","access-control-allow-origin":"*","accept-ranges":"bytes","x-goog-hash":"crc32c=0phhaQ==,md5=n86U8VqODP0oTv6MVbrKwg==","date":"Sun, 24 Nov 2024 00:30:43 GMT","x-goog-stored-content-encoding":"identity","last-modified":"Fri, 01 Mar 2024 08:10:51 GMT","content-type":"text/typescript","x-guploader-uploadid":"AFiumC4_tN1GOzGmclugksDZeXw7NKBT4AyvceqMUsFcr6V7e2ronH3lRBhF9V9r_-EO363DZTOLWHpvoQ","server":"UploadServer","x-goog-metageneration":"1","content-length":"897","content-security-policy":"default-src 'none'; script-src 'none'; style-src 'none'; img-src 'none'; font-src 'none'; connect-src 'none'; frame-src 'none'; object-src 'none'; frame-ancestors 'none'; sandbox; form-action 'none';","x-goog-generation":"1709280651748058","x-goog-stored-content-length":"897","x-jsr-cache-status":"miss"},"url":"https://jsr.io/@todd/webserver-base/0.0.1/ui/static/script/mod.ts","time":1732408243}